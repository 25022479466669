<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <!-- Card -->
      <b-card>

        <b-row class="hr-form">

          <b-col
            cols="12"
          >

            <b-card-title>
              Fact Sheet (Home Care Services - April 2017)
              <br>
              <small>Services Home Care Organizations and Home Care Aides May Provide</small>
            </b-card-title>

            <validation-observer ref="simpleRules">
              <b-form>

                Home Care Organization(s) (HCO) arrange for home care services, which are non-medical services, by an affiliated Home Care Aide (HCA) to a client who needs assistance with activities of daily living. To provide and arrange for these services, HCOs are required to be licensed by the California Department of Social Services.<br><br>

                Health and Safety Code Section 1796.12(n) defines home care services as non-medical services and assistance provided by a registered Home Care Aide to a client who, because of advanced age or physical or mental disability, cannot perform these activities. Home care services include, but are not limited to:<br><br>

                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>bathing</li>
                      <li>exercising</li>
                      <li>making telephone calls</li>
                      <li>meal planning and preparation</li>
                      <li>dressing</li>
                    </ul>

                  </b-col>

                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>positioning</li>
                      <li>transferring / ambulating</li>
                      <li>feeding</li>
                      <li>companionship</li>
                      <li>housekeeping / laundry</li>
                    </ul>

                  </b-col>

                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>personal hygiene and grooming</li>
                      <li>transportation</li>
                      <li>shopping</li>
                      <li>toileting and incontinence care</li>
                    </ul>

                  </b-col>
                </b-row>

                HCAs may provide non-medical home care services and assist clients with activities of daily living. These services can include, but are not limited to:<br><br>

                <b-row>
                  <b-col
                    cols="12"
                    lg="6"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>Helping a client bathe, dress, or use the restroom.</li>
                      <li>Assisting the clients with exercising, transferring, ambulating, and/or positioning.</li>
                      <li>Housekeeping duties and laundry.</li>
                    </ul>

                  </b-col>

                  <b-col
                    cols="12"
                    lg="6"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>Driving the client to appointments or to run errands.</li>
                      <li>Preparing meals and assisting the client with eating.</li>
                      <li>Keeping a client company and assisting with telephone calls.</li>
                    </ul>

                  </b-col>
                </b-row>

                <hr>

                <p class="text-center mt-1">
                  <strong>Services Home Care Organizations and Home Care Aides May NOT Provide</strong>
                </p>

                <strong>HCOs are prohibited from arranging for medical services and HCAs cannot provide any medical services to clients.</strong> There is no waiver or any other type of consent that removes this prohibition as medical services may only be administered and provided by an authorized medical professional or agency. Thefollowing list contains examples of services, but is not an all-inclusive list, that HCAs <span class="span-underline">cannot</span> provide:<br><br>

                <b-row>
                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>diabetic insulin shots</li>
                      <li>catheter care</li>
                      <li>bed sore treatment</li>
                      <li>physical therapy</li>
                      <li>glucose readings (blood sugar)</li>
                    </ul>

                  </b-col>

                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>tube feedings and care</li>
                      <li>IV insertions</li>
                      <li>blood pressure</li>
                      <li>ventilator dependent care</li>
                    </ul>

                  </b-col>

                  <b-col
                    cols="12"
                    lg="4"
                  >

                    <ul
                      class="li-cus-margin"
                    >
                      <li>colostomy bag care</li>
                      <li>vital signs</li>
                      <li>post-surgery wound care</li>
                      <li>medication assistance*</li>
                    </ul>

                  </b-col>
                </b-row>

                <small>*HCAs may assist with medication that a client self-administers; however, HCAs are not authorized to assist with medication that requires administration or oversight by a licensed medical professional. For example, a HCA may remind clients to take their medication; however, they may not directly provide it to the client. A HCA may instruct clients to fill a pillbox per their physician’s instructions but cannot fill the pillbox for them. There is no “medication waiver” that will allow the HCO or HCA legal authority to assist with or provide medication.</small>

                <hr>

                <small>For more information regarding Home Care Organizations, Home Care Aides and home care services, please contact the Home Care Services Bureau by e-mail at <a href="mailto:HCSB@dss.ca.gov">HCSB@dss.ca.gov</a> or by telephone at <a href="tel:8774245778">(877) 424-5778</a>.</small>

                <!-- Button Submit -->
                <b-button
                  variant="primary"
                  type="submit"
                  class="mr-1 d-none"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>

              </b-form>
            </validation-observer>

          </b-col>
        </b-row>

      </b-card>

    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          disabled
          @click="$store.dispatch('app-hr20/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          class="mb-75"
          block
          disabled
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form19', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form21', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BRow, BCol, BForm, BButton, VBToggle,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import { ref, onUnmounted } from '@vue/composition-api'

import { getUserData } from '@/auth/utils'
import store from '@/store'
import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationObserver,

    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BButton,

    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr20'
    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      dateFormat: 'm-d-Y',
    }

    const userData = ref(getUserData())

    return {
      formsOptions,

      flatPickrConfig,

      userData,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      forms: this.$route.name,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.li-cus-margin {
  margin-top: -15px;
}

.span-underline {
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
